import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useInView } from "react-hook-inview";

const MovieVideo = () => {
  const [ref, isVisible] = useInView();

  const videoRef = useRef();

  useEffect(() => {
    if (isVisible) {
      videoRef?.current?.play();
    } else {
      videoRef?.current?.pause();
    }
  }, [isVisible, videoRef]);

  return (
    <div ref={ref}>
      <video
        ref={videoRef}
        loop
        playsInline
        src={"/video_1.mp4"}
        style={{ width: "100%", height: "100%" }}
      ></video>
    </div>
  );
};

export default MovieVideo;
