import React from "react";
import { Link } from "react-router-dom";

const PageTitle = (props) => {
  return (
    <section className="wpo-page-title" style={{ position: "relative" }}>
      {props.image && (
        <img
          src={props.image}
          alt={props.pageTitle}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            objectFit: "cover",
            zIndex: -1,
            width: "100%",
            height: "100%",
          }}
        />
      )}
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="wpo-breadcumb-wrap">
              <h2 style={{ color: props.titleColor || "" }}>
                {props.pageTitle}
              </h2>
              <ol className="wpo-breadcumb-wrap">
                <li style={{ color: props.descriptionColor || "" }}>
                  <Link
                    to="/home"
                    style={{ color: props.descriptionColor || "" }}
                  >
                    Home
                  </Link>
                </li>
                <li style={{ color: props.descriptionColor || "" }}>
                  <span>{props.pagesub}</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;
