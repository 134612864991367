import simg2 from "../images/services/service_2.jpg";
import simg3 from "../images/services/service_3.jpg";
import sNew1 from "../images/services/service_new_1.jpg";
import thumbnail1 from "../images/services/service_1_thumbnail.jpg";
import thumbnail2 from "../images/all_images/img_13.jpg";
import thumbnail3 from "../images/services/service_3_thumbnail.JPG";
import sinnermg1 from "../images/all_images/img_17.jpg";
import sinnermg2 from "../images/all_images/img_3.jpg";
import sinnermg3 from "../images/services/service_3_inner.JPG";
import service2Inner1 from "../images/all_images/img_21.jpg";
import service3Banner from "../images/services/service_3_bg.jpg";
import service1Banner from "../images/services/service_bg.jpg";
import s1InnerFirst from "../images/services/service_inner_1_first.jpg";
import s1InnerSecond from "../images/services/service_inner_1_second.jpg";
import s5Banner from "../images/services/service_5_banner.jpg";
import s5Thumbnail from "../images/services/service_5_thumbnail.jpg";
import s5Inner from "../images/services/service_5_inner.jpg";
import s5Img from "../images/services/service_4_thumbnail.jpg";

const Services = [
  {
    id: "1",
    title: "茅野市民館",
    description:
      "Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.",
    simg1: simg3,
    thumbnail: thumbnail1,
    ssImg: sNew1,
    sinnerImg1: s1InnerFirst,
    sinnerImg2: s1InnerSecond,
    banner: service1Banner,
    titleColor: undefined,
    descriptionColor: undefined,
    allowSinglePage: true,
    textHeading: "茅野市民館＆Cafe Andante",
    textLine1: `茅野駅に隣接する茅野市民館`,
    textLine2: `中庭で行うセレモニーとカフェアンダンテで行うパーティー\n市民にとって縁のあるこの場所でゲストに見守られての結婚式`,
  },
  {
    id: "2",
    title: "八ヶ岳自然文化園",
    description:
      "Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.",
    simg1: simg2,
    thumbnail: thumbnail2,
    ssImg: sinnermg1,
    sinnerImg1: service2Inner1,
    sinnerImg2: sinnermg2,
    banner: null,
    titleColor: undefined,
    descriptionColor: undefined,
    allowSinglePage: true,
    textHeading: `八ヶ岳自然文化園・deli&cafe K`,
    textLine1: `八ヶ岳の麓　原村に位置する八ヶ岳自然文化園`,
    textLine2: `高原の爽やかな風と小鳥の声が聞こえる屋外での\n海外ウエディングのようなセレモニーと \n親しい方々と和やかに行うパーティー`,
  },
  {
    id: "3",
    title: "コミン家",
    description:
      "Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.",
    simg1: simg3,
    thumbnail: thumbnail3,
    ssImg: sinnermg3,
    sinnerImg1: null,
    sinnerImg2: null,
    banner: service3Banner,
    titleColor: "#FFF",
    descriptionColor: "#FFF",
    allowSinglePage: true,
    textHeading: "コミン家　家族婚",
    textLine1: `おふたりとご家族だけの少人数の家族婚
    木のぬくもりがあたたかく
    ゆっくりと話をしながら行える結婚式`,
    textLine2: null,
  },
  // {
  //   id: "4",
  //   title: "蓼科東急ホテル",
  //   description:
  //     "Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.",
  //   simg1: simg4,
  //   thumbnail: thumbnail4,
  //   ssImg: singleImg4,
  //   sinnerImg1: sinnermg1,
  //   sinnerImg2: sinnermg2,
  //   banner: null,
  //   titleColor: undefined,
  //   descriptionColor: undefined,
  //   allowSinglePage: true,
  //   textHeading: null,
  //   textLine1: null,
  //   textLine2: null,
  // },
  {
    id: "4",
    title: "蓼科東急ホテル",
    description:
      "Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.",
    simg1: s5Banner,
    thumbnail: s5Thumbnail,
    ssImg: s5Inner,
    sinnerImg1: s5Img,
    sinnerImg2: s5Thumbnail,
    banner: s5Banner,
    titleColor: "#fff",
    descriptionColor: "#fff",
    allowSinglePage: true,
    textHeading: null,
    textLine1: `夏の避暑リゾート地として有名な蓼科`,
    textLine2: `自然豊かなロケーションと寛ぎのホテルステイ\n大切な方々と旅行を兼ねて結婚式\nまた記念日に帰って来られるホテルウエディング`,
  },
];
export default Services;
