import React from "react";

const SectionTitle = (props) => {
  const { subTitle, mainTitle, backgroundImage } = props;
  const titleStyle = {
    backgroundImage: `url(${backgroundImage}) !important` || "",
  };

  return (
    <div className="wpo-section-title" style={titleStyle}>
      <h4>{subTitle}</h4>
      <h2>{mainTitle}</h2>
    </div>
  );
};

export default SectionTitle;
