import React from "react";
import abimg from "../../images/all_images/img_7.jpg";
import abimg2 from "../../images/all_images/img_2.jpg";
import { Link } from "react-router-dom";
import MovieVideo from "../MovieVideo";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const About = (props) => {
  return (
    <section className="wpo-about-section section-padding">
      <div className="container">
        <div className="wpo-about-wrap">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-item">
                <div className="wpo-about-img">
                  <img src={abimg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text">
                <h2>About Us</h2>
                <h4>
                  "Open Wedding" <br /> 心も身体も解放して
                  <br />
                  みんなと楽しむウエディング
                </h4>
                <p>
                  長野県茅野市での結婚式のお手伝いをしています
                  <br />
                  おふたりが好きなこと 大事にしていることを話しながら
                  <br />
                  おふたりらしい結婚式を一緒に考えます！
                  <br />
                  <br />
                  照れくさくて言えないことも結婚式だからこそ伝え合える
                  <br />
                  セレモニーは友人に司会をしてもらいアットホームな人前式
                  <br />
                  おふたりのために来てくださった皆様と
                  <br />
                  自由に話せる歓談メインのパーティー
                  <br />
                  エンドロールの映像で１日を一緒に振り返って笑顔で見送る
                  <br />
                  <br />
                  いつまでも結婚式のことを思い出して話したくなる
                  <br />
                  そんな１日をお手伝いいたします
                </p>
                <Link
                  onClick={ClickHandler}
                  to="/contact"
                  className="theme-btn"
                >
                  APPOINMENT
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="wpo-about-wrap">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12 order-lg-1 order-2">
              <div className="wpo-about-text">
                <h2>Our Story</h2>
                <p>
                  結婚式をお手伝いしていく中で
                  <br />
                  結婚式でこそ生まれる家族の物語を目の当たりにしてきました
                  <br />
                  また結婚式を行ったからこそ深まる絆があることも知りました
                  <br />
                  <br />
                  コロナ化で結婚式を延期や中止にしたカップルのお話を聞いて
                  <br />
                  屋外であれば密にならず
                  <br />
                  また開放的な場所での結婚式は心も解放してくれます
                  <br />
                  <br />
                  長野の大自然に囲まれた場所で
                  <br />
                  親しい皆さんと結婚式をしましょう！
                </p>
                <Link
                  onClick={ClickHandler}
                  to="/contact"
                  className="theme-btn"
                >
                  CONTACT US
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12 order-lg-2 order-1">
              <div className="wpo-about-item">
                <div className="wpo-about-img">
                  <img src={abimg2} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <MovieVideo />
      </div>
    </section>
  );
};

export default About;
