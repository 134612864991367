import React from "react";
import { Link } from "react-router-dom";
import lineImg from "../../images/line-gray.png";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const Footer = (props) => {
  return (
    <div className="wpo-site-footer text-center">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer-link">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/service">Services</Link>
                </li>
                <li>
                  <Link to="/portfolio">Portfolio</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12">
            <div className="link-widget">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100094077409090"
                    onClick={ClickHandler}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <i className="ti-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/yk_wedding_planner"
                    onClick={ClickHandler}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <i className="ti-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://page.line.me/373lkawi"
                    onClick={ClickHandler}
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={lineImg}
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12">
            <div className="copyright">
              <p>
                © Copyright 2024 | <Link to="/">Open Wedding</Link> | All right
                reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
