import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";
import Services from "../../api/service";
import ServiceSidebar from "./sidebar";
import Footer from "../../components/footer/Footer";

const SeviceSinglePage = (props) => {
  const { id } = useParams();

  const serviceDetails = Services.find((item) => item.id === id);

  //   const ClickHandler = () => {
  //     window.scrollTo(10, 0);
  //   };

  return (
    <Fragment>
      <Navbar />
      <PageTitle
        titleColor={serviceDetails.titleColor}
        descriptionColor={serviceDetails.descriptionColor}
        image={serviceDetails.banner}
        pageTitle={serviceDetails.title}
        pagesub={"Service"}
      />
      <div className="wpo-service-details-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="wpo-minimal-wrap">
                <div className="wpo-minimal-img">
                  <img src={serviceDetails.ssImg} alt="" />
                </div>
              </div>
              <div className="wpo-p-details-section">
                {serviceDetails.textHeading && (
                  <h5>{serviceDetails.textHeading}</h5>
                )}
                {serviceDetails.textLine1 && (
                  <pre>{serviceDetails.textLine1}</pre>
                )}
                {serviceDetails.textLine1 && (
                  <pre>{serviceDetails.textLine2}</pre>
                )}

                <div className="row">
                  <div className="col-md-6 col-sm-6 col-12">
                    <div className="wpo-p-details-img">
                      <img src={serviceDetails.sinnerImg1} alt="" />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-12">
                    <div className="wpo-p-details-img">
                      <img src={serviceDetails.sinnerImg2} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ServiceSidebar />
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default SeviceSinglePage;
