import React from "react";
import cimg from "../../images/all_images/img_15.jpg";
import cimg2 from "../../images/all_images/img_16.jpg";
import shape1 from "../../images/contact/1.png";
import shape2 from "../../images/contact/2.png";
import RSVPFrom from "../RSVPFrom/RSVPFrom";

const RsvpSection = (props) => {
  return (
    <section
      className={`wpo-contact-section section-padding mt-5 ${props.rClass}`}
    >
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col col-xl-4 col-lg-3 col-md-3 col-12">
            <div className="contact-img">
              <img src={cimg} alt="" />
            </div>
          </div>
          <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
            <div className="wpo-contact-section-wrapper">
              <div className="wpo-contact-form-area">
                <div className="wpo-section-title">
                  <h2>お問い合わせ</h2>
                </div>
                <RSVPFrom />
              </div>
              <div className="vector-1">
                <img src={shape1} alt="" />
              </div>
              <div className="vector-2">
                <img src={shape2} alt="" />
              </div>
            </div>
          </div>
          <div className="col col-xl-4 col-lg-3 col-md-3 col-12">
            <div className="contact-img">
              <img src={cimg2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RsvpSection;
