import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from "../../images/couples/couple_1.PNG";
import ts2 from "../../images/all_images/img_20.JPG";
import ts3 from "../../images/all_images/img_5.jpg";

const testimonial = [
  {
    id: "01",
    tImg: ts1,
    Des: `山﨑さんがプランナーとして携わってくださったおかけで\n今でも素敵な結婚式が出来たと思っています。\n\n親身になって結婚式のプランを一緒に考えてくださったり、\n細かな気遣いをいつもしてくださり、\n打ち合わせの時間はとても心地よく感じていました。\n\nそして結婚式の話だけではなく、山﨑さんは私たち夫婦のことを\n知ろうとしてくださり、たわいもない話や趣味の話などの\n日常会話もたくさんしてくださったので、山﨑さんとの距離も\nすぐに縮まり、打ち合わせでの不安な点や気になる点なども\nとても相談しやすかったです。\n\nそんな素敵な山﨑さんと出逢えたおかげで、結婚式を挙げて\n 6年経った今でも夫婦共々仲良くしていただき\n本当に感謝しています。`,
    Title: "N 様",
  },
  {
    id: "01",
    tImg: ts2,
    Des: `山﨑さんがプランナーとして携わってくださったおかけで\n今でも素敵な結婚式が出来たと思っています。\n\n親身になって結婚式のプランを一緒に考えてくださったり、\n細かな気遣いをいつもしてくださり、\n打ち合わせの時間はとても心地よく感じていました。\n\nそして結婚式の話だけではなく、山﨑さんは私たち夫婦のことを\n知ろうとしてくださり、たわいもない話や趣味の話などの\n日常会話もたくさんしてくださったので、山﨑さんとの距離も\nすぐに縮まり、打ち合わせでの不安な点や気になる点なども\nとても相談しやすかったです。\n\nそんな素敵な山﨑さんと出逢えたおかげで、結婚式を挙げて\n 6年経った今でも夫婦共々仲良くしていただき\n本当に感謝しています。`,
    Title: "N 様",
  },
];

const Testimonial = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  return (
    <section className="wpo-testimonial-section section-padding">
      <div className="container">
        <div className="wpo-testimonial-wrap">
          <div className="row align-items-center">
            <div className="col col-lg-7">
              <Slider
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
                dots={true}
                arrows={false}
              >
                {testimonial.map((tesmnl, tsm) => (
                  <div className="testimonial-img" key={tsm}>
                    <img src={tesmnl.tImg} alt="" />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="col col-lg-5">
              <div className="wpo-testimonial-items">
                <Slider
                  asNavFor={nav1}
                  ref={(slider2) => setNav2(slider2)}
                  slidesToShow={1}
                  fade={true}
                  swipeToSlide={true}
                  focusOnSelect={true}
                >
                  {testimonial.map((tesmnl, tsm) => (
                    <div className="wpo-testimonial-item" key={tsm}>
                      <div className="wpo-testimonial-text">
                        <i className="fi flaticon-quotation"></i>
                        <pre>{tesmnl.Des}</pre>
                        <div className="wpo-testimonial-text-btm">
                          <h3>{tesmnl.Title}</h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
