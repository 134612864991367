import React from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import Logo from "../../images/logo.jpg";
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";
import logoImg from "../../images/logo.jpg";
import lineImg from "../../images/line.webp";

const Header = (props) => {
  return (
    <header id="header" className={props.topbarNone}>
      <div className={`wpo-site-header wpo-header-style-1 ${props.hclass}`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-2 d-lg-none d-block">
                <div className="mobail-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="col-lg-6 col-4 d-lg-none dl-block">
                <div className="navbar-header">
                  <Link className="navbar-brand" to="/">
                    <img
                      src={Logo}
                      alt=""
                      style={{ width: "100px", height: "auto" }}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-2 col-6">
                <div className="social-info">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=100094077409090"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i className="fi flaticon-facebook-app-symbol facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/yk_wedding_planner"
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{ color: "#d62976" }}
                      >
                        <i className="fi flaticon-instagram instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://page.line.me/373lkawi"
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{ color: "#d62976" }}
                      >
                        <img
                          src={lineImg}
                          style={{
                            width: "30px",
                            height: "30px",
                            minWidth: "30px",
                            minHeight: "30px",
                          }}
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-8 col-md-1 col-1 d-lg-block d-none">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li className="menu-item-has-children">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/about">About</Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/service">Services</Link>
                    </li>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={logoImg}
                        alt="logo img"
                        style={{
                          width: "150px",
                          height: "auto",
                          objectFit: "contain",
                          margin: "auto 20px",
                        }}
                      />
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/portfolio">Portfolio</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};
export default connect(mapStateToProps, { removeFromCart })(Header);
