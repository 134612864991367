import React, { Fragment } from "react";
import { connect } from "react-redux";
import { addToCart, addToWishList } from "../../store/actions/action";
import api from "../../api";
import About from "../../components/about/about";
import BannerSec from "../../components/BannerSec/BannerSec";
import Hero from "../../components/hero/Hero";
import Navbar from "../../components/Navbar/Navbar";
import ProjectSection from "../../components/ProjectSection/ProjectSection";
import Scrollbar from "../../components/scrollbar/scrollbar";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import Testimonial from "../../components/Testimonial/Testimonial";
import RsvpSection from "../../components/RsvpSection/RsvpSection";
import Footer from "../../components/footer/Footer";

const HomePage = ({ addToCart, addToWishList }) => {
  return (
    <Fragment>
      <Navbar />
      <Hero />
      <About />
      <ServiceSection pbClass={"pt-0"} />
      {/* <FunFact /> */}
      <Testimonial />
      {/* <ProjectSection /> */}
      <BannerSec />
      {/* <PartnerSection /> */}
      <RsvpSection rClass={"pt-0"} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default connect(null, { addToCart, addToWishList })(HomePage);
