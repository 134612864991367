import React from "react";
const BannerSec = (props) => {
  return (
    <section className="wpo-banner-section">
      <h4>We Are Waiting for Celebrate Your Love</h4>
    </section>
  );
};

export default BannerSec;
