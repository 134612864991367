import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "../HomePage";
import AboutPage from "../AboutPage/AboutPage";
import PortfolioGridPage from "../PortfolioGridPage/PortfolioGridPage";
import ContactPage from "../ContactPage/ContactPage";
import ServicePage from "../ServicePage/ServicePage";
import PricingPage from "../PricingPage/PricingPage";
import SeviceSinglePage from "../SeviceSinglePage";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/service" element={<ServicePage />} />
          <Route path="service-single/:id" element={<SeviceSinglePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/portfolio" element={<PortfolioGridPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
