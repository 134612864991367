import React from "react";
import RsvpSection from "../RsvpSection/RsvpSection";
import lineImg from "../../images/line.webp";

const Contactpage = () => {
  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-10 offset-lg-1">
            <div className="office-info">
              <div className="row">
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://page.line.me/373lkawi"
                  className="col col-xl-3 col-lg-6 col-md-6 col-12"
                >
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon" style={{ marginBottom: "10px" }}>
                        <img
                          src={lineImg}
                          style={{ width: "40px", height: "40px" }}
                        />
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Line</h2>
                    </div>
                  </div>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.instagram.com/yk_wedding_planner"
                  className="col col-xl-3 col-lg-6 col-md-6 col-12"
                >
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-instagram instagram-icon"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Instagram</h2>
                    </div>
                  </div>
                </a>
                <a
                  href={"mailto:kaori.yamazaki608@gmail.com"}
                  className="col col-xl-3 col-lg-6 col-md-6 col-12"
                >
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-email"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Email Us</h2>
                      <p>kaori.yamazaki608@gmail.com</p>
                    </div>
                  </div>
                </a>
                <div className="col col-xl-3 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-phone-call"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Call Now</h2>
                      <p>090 1866 3101</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RsvpSection rClass={"pb-5"} />
      <section className="wpo-contact-map-section">
        <div className="wpo-contact-map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3228.9803072575073!2d138.26342737628133!3d35.971906913950924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601c4559c65a3229%3A0x50b931b2e1e71513!2z44OH44Oq77yG44Kr44OV44Kn44CMS-OAjQ!5e0!3m2!1sen!2sus!4v1704974037421!5m2!1sen!2sus"></iframe>
        </div>
      </section>
    </section>
  );
};

export default Contactpage;
