import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-hot-toast";
import emailJs from "@emailjs/browser";

const RSVPFrom = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    time: "",
    guests: "",
    message: "",
  });
  const changeHandler = (e) => {
    setForms((pv) => ({ ...pv, [e.target.name]: e.target.value }));
  };

  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      toast.loading("Sending your enquiry", { id: "SEND_ENQUIRY" });
      await emailJs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          to_name: "Yamazaki Wedding",
          from_name: forms.name,
          from_email: forms.email,
          message: forms.message,
          guests: forms.guests,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );
      toast.success("Enquiry sent successfully", { id: "SEND_ENQUIRY" });
      setForms({
        name: "",
        email: "",
        time: "",
        guests: "",
        message: "",
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Something went wrong", { id: "SEND_ENQUIRY" });
    }
  };

  return (
    <form onSubmit={submitHandler} className="contact-validation-active">
      <div className="row">
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <input
              value={forms.name}
              type="text"
              name="name"
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="お名前"
            />
          </div>
        </div>
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <input
              value={forms.email}
              type="email"
              name="email"
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="Email アドレス"
            />
          </div>
        </div>
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <input
              value={forms.time}
              type="text"
              name="time"
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="結婚式を検討している時期"
            />
          </div>
        </div>
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <select
              onChange={(e) => changeHandler(e)}
              value={forms.guests}
              type="text"
              className="form-control"
              name="guests"
            >
              <option value={"Not selected"}>列席者人数</option>
              <option value={"10"}>10</option>
              <option value={"25"}>25</option>
              <option value={"50"}>50</option>
              <option value={"75"}>75</option>
              <option value={"100"}>100</option>
            </select>
          </div>
        </div>

        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <textarea
              value={forms.message}
              type="text"
              style={{
                height: "200px",
                border: "1px solid white",
                borderRadius: "10px",
              }}
              name="message"
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="その他・要望"
            />
          </div>
        </div>
      </div>
      <div className="submit-area">
        <button type="submit" className="theme-btn-s3">
          メールを送る
        </button>
      </div>
    </form>
  );
};

export default RSVPFrom;
